import React from 'react'

const Footer = () => {
    return (
        <section className="section py-4 position-relative">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col">
                        <ul className="list-inline list-with-separator mb-0">
                            <li className="list-inline-item me-0">
                                <a href="#">About</a>
                            </li>
                            <li className="list-inline-item me-0">
                                <a href="#">Privacy</a>
                            </li>
                            <li className="list-inline-item me-0">
                                <a href="#">Terms</a>
                            </li>
                            <li className="list-inline-item me-0">
                                <a href="#">Developers</a>
                            </li>
                            <li className="list-inline-item me-0">
                                <a href="#">Support</a>
                            </li>
                            <li className="list-inline-item me-0">
                                <a href="#">
                                    Careers{" "}
                                    <span className="align-middle badge badge-soft-info rounded-pill fw-normal fs-11 px-2 py-1">
                                        We're hiring
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-auto text-md-end mt-2 mt-md-0">
                        <p className="fs-14 mb-0">
                            {" "}
                            © Paytez. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Footer
