import { createSlice } from '@reduxjs/toolkit'



export const counterSlice = createSlice({
    name: 'web3',
    initialState: {
        provider: null,
        address: false,
        signer: null,
        web3Modal: null,
        connected: false,
        payablecontract: null,
        receivecontract: null,
        plugincontract: null,
        providerConnect: null,
        chainId: null,
        balance: 0.00,
        networkName: null,
        symbol: null,
        icon: "https://ui-avatars.com/api/?background=244b81&color=fff&name=EVM"
    },
    reducers: {
        clearWeb3: async (state, action) => {
            state.provider = null
            state.address = false
            state.signer = null
            state.web3Modal = null
            state.connected = false
            state.payablecontract = null
            state.receivecontract = null
            state.plugincontract = null
            state.providerConnect = null
            state.chainId = null
            state.balance = 0.00
            state.networkName = null
            state.symbol = null
            state.icon = "https://ui-avatars.com/api/?background=244b81&color=fff&name=EVM"
            window.location.reload()
        },
        setWeb3: (state, action) => {
            state.provider = action.payload.provider
            state.address = action.payload.address
            state.signer = action.payload.signer
            state.web3Modal = action.payload.web3Modal
            state.payablecontract = action.payload.payablecontract
            state.receivecontract = action.payload.receivecontract
            state.plugincontract = action.payload.plugincontract
            state.connected = true
            state.providerConnect = action.payload.providerConnect
            state.chainId = action.payload.chainId
            state.symbol = action.payload.symbol
            state.networkName = action.payload.networkName
            state.icon = action.payload.icon




        },
        setWeb3Test: (state, action) => {
            state.connected = action.payload.connected
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        }

    },
})

export const { clearWeb3, setWeb3, setWeb3Test, setBalance } = counterSlice.actions



export default counterSlice.reducer
