import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const SelectFieldProfile = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  forgotView,
  forgotLink,
  isRequired,
  max,
  desc,
  accept,
  id,
  options
}) => {

  return (
    <div className="row g-3 align-center py-2">
      <div className="col-lg-5">
        <div className="form-group">
          <label className="form-label" htmlFor="site-name">{label} {isRequired && "*"} </label>
          <span className="form-note">{desc}</span>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="form-group">
          <div className="form-control-wrap">
            <select disabled={disabled} id={id} onChange={onChange} value={value} name={name} className={classnames('form-control', {
              'is-invalid': error
            })}>
              <option value="">Select</option>
              {options.map(result => {
                if (!result.value) {
                  return <option value={result}>{result}</option>
                }
                return <option value={result.value}>{result.label}</option>
              })}

            </select>


            {info && <small className="form-text text-muted">{info}</small>}
            {error && (<div className="invalid-feedback text-left text-danger">{error}</div>)}
          </div>
        </div>
      </div>
    </div>

  )
}

SelectFieldProfile.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  forgotView: PropTypes.string,
  forgotLink: PropTypes.string,
  isRequired: false
}

SelectFieldProfile.defaultProps = {
  type: 'text',
  desc: "",
}

export default SelectFieldProfile;