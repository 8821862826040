import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
const BaseLayout = ({ children }) => {
    return <>
        <Navbar />
        {children}
        <Footer />
    </>
}
export default BaseLayout;