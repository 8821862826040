import React, { useEffect, useState, useRef } from 'react'
import BaseLayout from '../commonlayout/BaseLayout'
import TextField from '../common/TextField'
import SettingSidebar from './SettingSidebar'
import axios from 'axios'
import { API_URL, BlockPopup, CURRENCY_LIST, IMAGE_URL, ToastPopup, uploadFileServer } from '../../actions/constant'
import generateApiKey from 'generate-api-key';
import SelectField from '../common/InlineFields/SelectField'

const Currency = () => {
    const fileInputRef = useRef(null);
    const initData = {
        defaultCurrency: "USD"
    }

    const [data, setdata] = useState(initData)
    const [errors, seterrors] = useState({})





    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        BlockPopup.standard('.currencysetting', `Please wait... Don't Close`);
        try {
            let result = await axios.get(API_URL + "/api/usersetting/")
            BlockPopup.remove('.currencysetting');
            if (result.data) {
                setdata(result.data)
            }

        } catch (err) {
            BlockPopup.remove('.currencysetting');
            ToastPopup.failure("Error Ocuured in API")
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        BlockPopup.standard('.currencysetting', `Please wait... Don't Close`);

        try {
            let result = await axios.post(API_URL + "/api/usersetting/", data)
            if (result.data) {
                BlockPopup.remove('.currencysetting');
                ToastPopup.success("Successfully Added")
            }

        } catch (err) {
            BlockPopup.remove('.currencysetting');
            if (err && err.response && err.response.data) {
                seterrors(err.response.data)
            }
        }

    }



    return (
        <BaseLayout>
            <section className="position-relative p-3 bg-gradient2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title">
                                <h3 className="my-0">Currency Settings</h3>
                                <p className="mt-1 fw-medium">Change your currency settings</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            {/* menu start */}
                                            <SettingSidebar />
                                            {/* menu end */}
                                        </div>
                                        <div className="col-lg-9 currencysetting">
                                            <div className="tab-content p-0">
                                                {/* account form start */}
                                                <div className="tab-pane fade show active px-3" id="account">
                                                    <h4 className="mt-0">Default Currency for all Application</h4>
                                                    <form onSubmit={onSubmit} className="account-form">


                                                        {/* basic info start */}
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <SelectField
                                                                        isRequired
                                                                        label="Default Currency"
                                                                        options={CURRENCY_LIST}
                                                                        onChange={(e) => setdata({ ...data, defaultCurrency: e.target.value })}
                                                                        value={data.defaultCurrency}
                                                                        placeholder=""
                                                                        error={errors.defaultCurrency}
                                                                    />

                                                                </div>


                                                            </div>


                                                        </div>
                                                        {/* account removal end */}
                                                        <hr className="my-4" />
                                                        {/* save start */}
                                                        <div className="row mt-2">
                                                            <div className="col-lg-12">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* save end */}
                                                    </form>
                                                </div>
                                                {/* account form end */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </BaseLayout>
    )
}

export default Currency
