import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './reducers/authReducer';
import { logoutUser } from './actions/authAction';

import axios from 'axios'
import { API_URL, AVAILABLE_NETWORK, RPC_URL_BY_CHAIN } from './actions/constant'
import Loader from "./components/common/Spinner"

import { listSetting } from './reducers/settingReducer';


import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { store, persistor } from './store';
import store from './store';
import './App.css';
import PrivateRoute from './components/common/PrivateRoute';
import { ethers } from 'ethers';
import { setWeb3 } from './reducers/web3Reducer';
import PaymentTest from './components/layouts/PaymentTest';
import Currency from './components/setting/Currency';
import { listUserSetting } from './reducers/usersettingReducer';
import { magic } from './lib/magic';

axios.defaults.baseURL = API_URL;

const Landing = React.lazy(() => import('./components/layouts/Landing'));
const About = React.lazy(() => import('./components/layouts/aboutus/About'));
const Product = React.lazy(() => import('./components/layouts/product/Product'));
const Developer = React.lazy(() => import('./components/layouts/developer/Developer'));
const Contact = React.lazy(() => import('./components/layouts/contact/Contact'));





const Login = React.lazy(() => import('./components/auth/Login'));

const Auth = React.lazy(() => import('./components/auth/Auth'));

const Dashboard = React.lazy(() => import('./components/dashboard/Dashboard'));

const Invoice = React.lazy(() => import('./components/invoice/Invoice'));
const ViewInvoice = React.lazy(() => import('./components/invoice/ViewInvoice'));

const QRPayments = React.lazy(() => import('./components/qrpayment/Invoice'));

const ViewQrCodePayment = React.lazy(() => import('./components/qrpayment/ViewQrCodePayment'));
const ViewQrInvoice = React.lazy(() => import('./components/qrpayment/ViewQrInvoice'));

const WebPaymentInvoice = React.lazy(() => import('./components/webpayment/Invoice'));
const ViewWebPaymentInvoice = React.lazy(() => import('./components/webpayment/ViewInvoice'));





const Setting = React.lazy(() => import('./components/setting/Setting'));
const QrSetting = React.lazy(() => import('./components/setting/QrSetting'));
const WebSetting = React.lazy(() => import('./components/setting/WebSetting'));
const ApiKey = React.lazy(() => import('./components/setting/ApiKey'));



















// store.dispatch(listSetting());


// localStorage.clear()


if (localStorage.jwtToken) {
  //SET WEB3


  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  console.log("decoded", decoded)
  store.dispatch(setCurrentUser(decoded));
  store.dispatch(listUserSetting());
  setupWeb3()

  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/login';
  }

}

async function setupWeb3() {
  // const provider = new ethers.providers.JsonRpcProvider(RPC_URL_BY_CHAIN[localStorage.chainId]);
  console.log("magic.rpcProvider", magic.rpcProvider)
  const provider = await new ethers.providers.Web3Provider(magic.rpcProvider);
  await provider.send('eth_requestAccounts', []);
  console.log("PROVIDER", provider)
  const { chainId } = await provider.getNetwork()
  console.log("chainId", chainId)

  // const signer = new ethers.Wallet(localStorage.pkey, provider);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  console.log("address", address)
  const balvalue = await signer.getBalance();
  const result = ethers.utils.formatUnits(balvalue, 18);
  const balance = ethers.utils.formatEther(
    await provider.getBalance(address), // Balance is in wei
  );
  console.log("balance", balance)


  var findNetwork = AVAILABLE_NETWORK.find(x => x.networkID === parseInt(localStorage.chainId))
  const web3Data = {
    // providerConnect: providerConnect,
    provider: provider,
    address: address,
    signer: signer,
    connected: true,
    chainId: localStorage.chainId,
    symbol: findNetwork.symbol,
    networkName: findNetwork.networkName,
    icon: findNetwork.icon

  }
  store.dispatch(setWeb3(web3Data))
}




export default function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Router
        exact
        path="/"
      >
        <Suspense fallback={<Loader />}>
          {/* <div class="nk-app-root"> */}
          {/* ADMIN ROUTE */}
          <Routes>
            <Route index exact path="/" element={<Landing />} />
            <Route index exact path="/aboutus" element={<About />} />
            <Route index exact path="/features" element={<Product />} />
            <Route index exact path="/developers" element={<Developer />} />
            <Route index exact path="/contact" element={<Contact />} />




            <Route index exact path="/login" element={<Login />} />
            <Route index exact path="/auth" element={<Auth />} />
            <Route index exact path="/paymenttest" element={<PaymentTest />} />

            <Route index exact path="/viewinvoice/:id" element={<ViewInvoice />} />

            <Route index exact path="/viewwebpayment/:id" element={<ViewWebPaymentInvoice />} />


            <Route index exact path="/viewqrcode/:userID/:walletAddress" element={<ViewQrCodePayment />} />

            <Route index exact path="/viewqrinvoice/:id" element={<ViewQrInvoice />} />









            <Route path="/" element={<PrivateRoute role="user" />}>
              <Route index exact path="/user/dashboard" element={<Dashboard />} />
              <Route index exact path="/user/invoice" element={<Invoice />} />
              <Route index exact path="/user/qrpayments" element={<QRPayments />} />

              <Route index exact path="/user/webpayment" element={<WebPaymentInvoice />} />



              <Route index exact path="/user/setting" element={<Setting />} />
              <Route index exact path="/user/setting/qr" element={<QrSetting />} />
              <Route index exact path="/user/setting/web" element={<WebSetting />} />
              <Route index exact path="/user/setting/apikey" element={<ApiKey />} />
              <Route index exact path="/user/setting/currency" element={<Currency />} />




            </Route>



          </Routes>
          {/* </div> */}
        </Suspense>
      </Router>
      {/* </PersistGate> */}
    </Provider>
  )
}
