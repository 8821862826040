import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { setError } from './errorReducer'
import { API_URL } from '../actions/constant';



export const counterSlice = createSlice({
    name: 'setting',
    initialState: {
        listsetting: {}
    },
    reducers: {
        setSetting: (state, action) => {
            state.listsetting = action.payload

        },
    },
})

export const { setSetting } = counterSlice.actions

//API CALLS
//Login - Get User Token
export const listSetting = () => dispatch => {
    axios.get(API_URL + '/api/setting')
        .then(res => {
            dispatch(setSetting(res.data));
        })
        .catch(err => {
            console.log("err", err)
            dispatch(setError(err.response.data))
        });
};



export default counterSlice.reducer
