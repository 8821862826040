import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const TextField = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  disabled,
  forgotView,
  forgotLink,
  isRequired,
  id

}) => {

  return (

    <>
      <label lassName={`form-label `}>
        {label} <small>{isRequired && "*"}</small>
      </label>
      <input type={type} id={id}
        className={classnames('form-control', {
          'is-invalid': error
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled} />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && (<div className="invalid-feedback text-left text-danger">{error}</div>)}
    </>






  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
  label: PropTypes.string,
  forgotView: PropTypes.string,
  forgotLink: PropTypes.string,
  isRequired: false,
  id: PropTypes.string
}

TextField.defaultProps = {
  type: 'text'
}

export default TextField;