import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { setError } from './errorReducer'
import { API_URL } from '../actions/constant';



export const counterSlice = createSlice({
    name: 'usersetting',
    initialState: {
        userconfig: {
            defaultCurrency: "USD"
        }
    },
    reducers: {
        setUserSetting: (state, action) => {
            state.userconfig = action.payload

        },
    },
})

export const { setUserSetting } = counterSlice.actions

//API CALLS
//Login - Get User Token
export const listUserSetting = () => dispatch => {
    axios.get(API_URL + '/api/usersetting')
        .then(res => {
            if (res.data) {
                dispatch(setUserSetting(res.data));
            }

        })
        .catch(err => {
            console.log("err", err)
            dispatch(setError(err.response.data))
        });
};



export default counterSlice.reducer
