import React, { useEffect } from "react";
import { logoutUser } from '../../actions/authAction'
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import appendScript from "../../utils/appendScript"
import removeScript from "../../utils/removeScript"
import { AVAILABLE_NETWORK, ToastPopup } from "../../actions/constant"

import truncateEthAddress from 'truncate-eth-address'

const Navbar = ({ children, }) => {
    const { key } = useLocation()

    useEffect(() => {
        appendScript("./assets/js/vendor.min.js")
        appendScript("./assets/js/theme.min.js")

        return () => {
            removeScript("./assets/js/vendor.min.js")
            removeScript("./assets/js/theme.min.js")
        }

    }, [key])
    const dispatch = useDispatch()

    const { user, } = useSelector(state => state.auth)
    const { networkName, symbol } = useSelector(state => state.web3)

    const navlinks = [
        { name: "Dashboard", link: "/user/dashboard", icon: <i data-feather="pie-chart" ></i> },

        { name: "Invoices", link: "/user/invoice", icon: <i data-feather="file" ></i> },
        {
            name: "QR Pay", link: "/user/qrpayments", icon: <i data-feather="camera" ></i>
        },
        { name: "Web Payments", link: "/user/webpayment", icon: <i data-feather="shopping-bag" ></i> },
        // { name: "API Keys", link: "/user/setting/apikey", icon: <i data-feather="send" ></i> },
    ]
    const pathname = window.location.pathname;

    const navRender = navlinks.map(result => {
        var currentLink
        if (pathname === result.link) {
            currentLink = "active"
        }
        return <li className="nav-item pe-3">
            <Link className={`nav-link ${currentLink}`} to={result.link}>
                <div className="d-flex align-items-center">
                    <span className="icon-xs me-1 flex-shrink-0">
                        {/*?xml version="1.0" encoding="UTF-8"?*/}
                        {result.icon}
                    </span>
                    <div className="flex-grow-1">{result.name}</div>
                </div>
            </Link>
        </li>
    })

    var photoLink = "../../assets/images/avatars/img-8.jpg"
    if (user.photo) {
        photoLink = user.photo
    }
    return <header>
        <nav className="navbar navbar-expand-lg topnav-menu navbar-light shadow">
            <div className="container">
                <a className="navbar-brand me-lg-3 me-auto" href="/">
                    <img
                        src="./assets/images/logo.png"
                        height={50}
                        className="d-inline-block align-top"
                        alt=""
                    />
                </a>
                <a
                    href="#"
                    className="navbar-toggler me-3"
                    data-bs-toggle="collapse"
                    data-bs-target="#topnav-menu-content4"
                    aria-controls="topnav-menu-content4"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </a>
                <div className="collapse navbar-collapse" id="topnav-menu-content4">
                    <ul className="navbar-nav mx-auto">
                        {navRender}
                    </ul>
                    <ul className="navbar-nav align-items-center">
                        {/* notification start */}
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="notifications"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="icon-xs">
                                    {/*?xml version="1.0" encoding="UTF-8"?*/}
                                    <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                    >
                                        {/* Generator: Sketch 52.2 (67145) - http://www.bohemiancoding.com/sketch */}
                                        <title>Stockholm-icons / General / Notification#2</title>
                                        <desc>Created with Sketch.</desc>
                                        <g
                                            id="Stockholm-icons-/-General-/-Notification#2"
                                            stroke="none"
                                            strokeWidth={1}
                                            fill="none"
                                            fillRule="evenodd"
                                        >
                                            <rect id="bound" x={0} y={0} width={24} height={24} />
                                            <path
                                                d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                                                id="Combined-Shape"
                                                fill="#335EEA"
                                            />
                                            <circle
                                                id="Oval"
                                                fill="#335EEA"
                                                opacity="0.3"
                                                cx="18.5"
                                                cy="5.5"
                                                r="2.5"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <small style={{ width: 50, }}>{networkName}</small>

                            </a>
                            <div className="dropdown-menu p-2" aria-labelledby="notifications">

                                {AVAILABLE_NETWORK.filter(x => x.symbol === symbol).map(result => {
                                    return <Link to={"/customselect"} onClick={(e) => {
                                        e.preventDefault()
                                        localStorage.setItem("chainId", result.networkID)
                                        window.location.reload()
                                    }} className="dropdown-item p-2">
                                        {result.networkName}
                                    </Link>
                                })}


                            </div>
                        </li>
                        {/* notification end */}
                        {/* profile dropdown start */}
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle py-0"
                                href="#"
                                id="user"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            src={photoLink}
                                            className="avatar avatar-xs rounded-circle me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-1 lh-base">
                                        <span className="fw-semibold fs-13 d-block line-height-normal">
                                            {user.firstName}
                                        </span>
                                        <span className="text-muted fs-13">{user.walletAddress && truncateEthAddress(user.walletAddress)}</span>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-menu p-2" aria-labelledby="user">
                                {/* item start */}
                                {/* <a className="dropdown-item p-2" href="#">
                                    <i
                                        className="icon icon-xxs me-1 icon-dual"
                                        data-feather="user"
                                    />
                                    Profile
                                </a> */}
                                {/* item end */}
                                {/* item start */}
                                <Link to="/user/setting" className="dropdown-item p-2" >
                                    <i
                                        className="icon icon-xxs me-1 icon-dual"
                                        data-feather="settings"
                                    />
                                    Settings
                                </Link>
                                {/* item end */}

                                {/* item start */}
                                <Link to="/user/setting" onClick={(e) => {
                                    e.preventDefault()
                                    ToastPopup.info("Not available now")
                                }} className="dropdown-item p-2" href="#">
                                    <i
                                        className="icon icon-xxs me-1 icon-dual"
                                        data-feather="aperture"
                                    />
                                    Support
                                </Link>
                                {/* item end */}
                                <div className="dropdown-divider" />
                                {/* item start */}
                                <Link className="dropdown-item p-2" to="/logout" onClick={() => {
                                    dispatch(logoutUser())
                                }}>
                                    <i
                                        className="icon icon-xxs me-1 icon-dual"
                                        data-feather="unlock"
                                    />
                                    Sign Out
                                </Link>
                                {/* item end */}
                            </div>
                        </li>
                        {/* profile dropdown end */}
                    </ul>
                </div>
            </div>
        </nav>
    </header >


}
export default Navbar;