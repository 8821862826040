import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SettingSidebar = ({ name }) => {
    const { pathname } = useLocation()

    console.log("pathname", pathname)

    const navLinks = [
        { name: "Invoice Setting", link: "/user/setting" },
        { name: "QR Setting", link: "/user/setting/qr" },
        { name: "Web Payments", link: "/user/setting/web" },
        { name: "API Keys", link: "/user/setting/apikey" },
        { name: "Currency", link: "/user/setting/currency" },



    ]
        .map(result => {
            let className = "nav-link"
            if (pathname === result.link) {
                className = "nav-link active"
            }
            return <li className="nav-item">
                <Link
                    to={result.link}
                    className={className}
                >
                    <span>{result.name}</span>
                </Link>
            </li>
        })
    return (
        <ul className="nav navtab-bg nav-pills flex-column">

            {navLinks}
        </ul>
    )
}

export default SettingSidebar
