import React, { useEffect } from 'react'

const PaymentTest = () => {
    useEffect({

    }, [])
    const checkInvoice = () => {

    }
    return (
        <h1>Payment Test</h1>

    )
}

export default PaymentTest
