import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import { customNodeOptions } from "../actions/constant";

console.log("customNodeOptions", customNodeOptions)
const createMagic = (key) => {
    return (
        typeof window !== "undefined" &&
        new Magic(key, {
            extensions: [new OAuthExtension()],
            network: customNodeOptions
        })
    );
};
console.log("process.env.REACT_APP_MAGIC_KEY", process.env.REACT_APP_MAGIC_KEY)

export const magic = createMagic(process.env.REACT_APP_MAGIC_KEY);
